import { render, staticRenderFns } from "./OvHtmlEmbed.vue?vue&type=template&id=6e70bf84"
import script from "./OvHtmlEmbed.vue?vue&type=script&lang=js"
export * from "./OvHtmlEmbed.vue?vue&type=script&lang=js"
import style0 from "./OvHtmlEmbed.vue?vue&type=style&index=0&id=6e70bf84&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports